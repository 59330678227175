<template>
  <div class="page-table only-y mainDiv mb-30">
    <div class="card-base baslikBg animated fadeInUp">
      {{ $t("src.views.apps.gecikme.liste.title") }}
      <div class="tanimlamaButon">

        <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
          <el-button v-on:click="refreshPage()" style="margin-left: 15px !important" type="text"
                     icon="el-icon-refresh"></el-button>
        </el-tooltip>
      </div>
    </div>

    <div class="page-header card-base card-shadow--medium animated fadeInUp"
         v-loading="loading || searchLoading || stateLoading"
         :element-loading-text='loading ? $t("src.views.apps.gecikme.liste.loading") : searchLoading ? $t("src.views.apps.gecikme.liste.arama") : "İş durumu değiştiriliyor. Lütfen bekleyiniz."'
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(255, 255, 255, 1)">
      <el-row class="mt-0 mb-10">
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <div class="container">
            <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9"
                            text-color="gray" :disabled="searchLoading">
              <el-radio-button label="2" v-if="yetkiTuru == 1">
                <i class="mdi mdi-clock-outline mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                Acil Bekliyor
              </el-radio-button>
              <el-radio-button label="0">
                <i class="mdi mdi-clock-outline mdi-18px" style="color: #f7ba2a; margin-right: 5px"></i>
                İncelenmeyi Bekliyor
              </el-radio-button>
              <el-radio-button label="1">
                <i class="mdi mdi-clock-outline mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                İncelendi
              </el-radio-button>
              <el-radio-button label="2" v-if="yetkiTuru == 2">
                <i class="mdi mdi-clock-outline mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                Üst Yöneticiye Gönderildi
              </el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <el-col :lg="24" :md="24" :sm="24" :xs="24">
            <ul :class="isDesktop ? 'islemler' : 'islemler2'" v-if="(yetkiTuru == '1' || yetkiTuru == '2')">
              <li class="aciklama">
                <i class="mdi mdi-comment-text-outline"></i> Açıklama
              </li>
              <li class="bildir" v-if="yetkiTuru == 1 && selectIcon != 2">
                <i class="mdi mdi-rotate-right"></i> Acil Bekliyor
              </li>
              <li class="duzenle" v-if="selectIcon != 0">
                <i class="mdi mdi-clock-alert-outline"></i> Beklemeye Al
              </li>
              <li class="incele" v-if="selectIcon != 1">
                <i class="mdi mdi-check"></i> İncele
              </li>
              <li class="bildir" v-if="yetkiTuru == 2 && selectIcon != 2">
                <i class="mdi mdi-rotate-right"></i> Üst Yöneticiye Gönder
              </li>
            </ul>
          </el-col>
        </el-col>
      </el-row>
      <el-table v-loading="searchLoading" border stripe :data="gecikmeler" style="width: 100%"
                :element-loading-text='"Arama Yapılıyor. Lütfen Bekleyin."'
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255, 255, 255, 1)">


        <el-table-column>
          <template slot="header">
          </template>
          <el-table-column
              :label='$t("src.views.apps.genel.sira")'
              type="index"
              :index="indexMethod"
              width="auto">
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="gecikmeAra"
                      :placeholder='$t("src.views.apps.gecikme.liste.search.sozlesmeKategoriAdiAra")'
                      v-model="sozlesmeKategoriAdiAra" v-debounce:500ms="gecikmeAra" size="mini"></el-input>
          </template>
          <el-table-column
              prop="sozlesmeKategoriAdi"
              label="Birim"
              width="auto">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.sozlesmeKategoriAdi }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="gecikmeAra"
                      :placeholder='$t("src.views.apps.gecikme.liste.search.personelAdiAra")' v-model="personelAdiAra"
                      v-debounce:500ms="gecikmeAra" size="mini"></el-input>
          </template>
          <el-table-column
              prop="personelAdi"
              label="Personel Adı"
              width="auto">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.adSoyad }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="gecikmeAra"
                      :placeholder='$t("src.views.apps.gecikme.liste.search.musteriAra")' v-model="musteriAdiAra"
                      v-debounce:500ms="gecikmeAra" size="mini"></el-input>
          </template>
          <el-table-column
              prop="musteriAdi"
              label="Müşteri Adı"
              width="auto">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.musteriAdi }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="gecikmeAra"
                      :placeholder='$t("src.views.apps.gecikme.liste.search.projeAra")' v-model="projeAdiAra"
                      v-debounce:500ms="gecikmeAra" size="mini"></el-input>
          </template>
          <el-table-column
              prop="projeAdi"
              label="Proje Adı"
              width="auto">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.projeBaslik }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="gecikmeAra"
                      :placeholder='$t("src.views.apps.gecikme.liste.search.sozlesmeAra")' v-model="sozlesmeAdiAra"
                      v-debounce:500ms="gecikmeAra" size="mini"></el-input>
          </template>
          <el-table-column
              prop="sozlesmeAdi"
              label="Sözleşme Adı"
              width="280">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.sozlesmeBaslik }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="gecikmeAra"
                      :placeholder='$t("src.views.apps.gecikme.liste.search.gecikenGunAra")' v-model="gecikenGunAra"
                      v-debounce:500ms="gecikmeAra" size="mini"></el-input>
          </template>
          <el-table-column
              prop="gecikenGun"
              :label='$t("src.views.apps.gecikme.liste.table.gecikenGun")'
              width="175">
            <template slot-scope="scope">
              {{ dateFarkHesapla(scope.row.tamamlanmaTarihi, scope.row.isTamamlanmaTarihi) }}
            </template>
          </el-table-column>
        </el-table-column>

        <!--

        <el-table-column>
            <template slot="header" slot-scope="props">
                <el-input clearable v-on:clear="gecikmeAra" :placeholder='$t("src.views.apps.gecikme.liste.search.aciklamaAra")' v-model="aciklamaAra" v-debounce:500ms="gecikmeAra" size="mini"></el-input>
            </template>
            <el-table-column
                prop="aciklama"
                :label='$t("src.views.apps.gecikme.liste.table.aciklama")'
                width="auto">
                <template slot-scope="props">
                    <div class="new-line-text">
                        {{props.row.aciklama}}
                    </div>
                </template>
            </el-table-column>
        </el-table-column>-->

        <el-table-column v-if="(yetkiTuru == '2') && selectIcon === '0'">
          <el-table-column
              :label='$t("src.views.apps.genel.islem")'
              align="right"
              width="150"
          >
            <template slot-scope="scope">

              <el-tooltip content='Açıklama' :open-delay="500" placement="bottom">
                <el-button @click="open(scope.row)"
                           style="margin-left: 15px !important; color:#1087d6"
                           type="text"
                           icon="mdi mdi-comment-text-outline mdi-24px"></el-button>
              </el-tooltip>

              <el-tooltip content='İncele' :open-delay="500" placement="bottom">
                <el-button @click="changeGecikmeDurum(scope.row, '1')"
                           style="margin-left: 15px !important; color:#13ce66"
                           type="text"
                           icon="mdi mdi-check mdi-24px"></el-button>
              </el-tooltip>

              <el-tooltip content='Gönder' :open-delay="500" placement="bottom">
                <el-button @click="changeGecikmeDurum(scope.row, '2')" class="buttonDel"
                           style="margin-left: 15px !important;"
                           type="text"
                           icon="mdi mdi-rotate-right mdi-24px"></el-button>
              </el-tooltip>

            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-if="(yetkiTuru == '1') && selectIcon === '0'">
          <el-table-column
              :label='$t("src.views.apps.genel.islem")'
              align="right"
              width="150"
          >
            <template slot-scope="scope">
              <el-tooltip content='Açıklama' :open-delay="500" placement="bottom">
                <el-button @click="open(scope.row)"
                           style="margin-left: 15px !important; color:#1087d6"
                           type="text"
                           icon="mdi mdi-comment-text-outline mdi-24px"></el-button>
              </el-tooltip>

              <el-tooltip content='Acil Beklemeye Al' :open-delay="500" placement="bottom">
                <el-button @click="changeGecikmeDurum(scope.row, '2')" class="buttonDel"
                           style="margin-left: 15px !important;"
                           type="text"
                           icon="mdi mdi-rotate-right mdi-24px"></el-button>
              </el-tooltip>
              <el-tooltip content='İncele' :open-delay="500" placement="bottom">
                <el-button @click="changeGecikmeDurum(scope.row, '1')"
                           style="margin-left: 15px !important; color:#13ce66"
                           type="text"
                           icon="mdi mdi-check mdi-24px"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-if="(yetkiTuru == '2') && selectIcon === '1'">
          <el-table-column
              :label='$t("src.views.apps.genel.islem")'
              align="right"
              width="150"
          >
            <template slot-scope="scope">

              <el-tooltip content='Açıklama' :open-delay="500" placement="bottom">
                <el-button @click="open(scope.row)"
                           style="margin-left: 15px !important; color:#1087d6"
                           type="text"
                           icon="mdi mdi-comment-text-outline mdi-24px"></el-button>
              </el-tooltip>

              <el-tooltip content='Beklemeye Al' :open-delay="500" placement="bottom">
                <el-button @click="changeGecikmeDurum(scope.row, '0')"
                           style="margin-left: 15px !important; color:#f7ba2a"
                           type="text"
                           icon="mdi mdi-clock-alert-outline mdi-24px"></el-button>
              </el-tooltip>

              <el-tooltip content='Gönder' :open-delay="500" placement="bottom">
                <el-button @click="changeGecikmeDurum(scope.row, '2')" class="buttonDel"
                           style="margin-left: 15px !important;"
                           type="text"
                           icon="mdi mdi-rotate-right mdi-24px"></el-button>
              </el-tooltip>

            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-if="(yetkiTuru == '1') && selectIcon === '1'">
          <el-table-column
              :label='$t("src.views.apps.genel.islem")'
              align="right"
              width="150"
          >
            <template slot-scope="scope">

              <el-tooltip content='Açıklama' :open-delay="500" placement="bottom">
                <el-button @click="open(scope.row)"
                           style="margin-left: 15px !important; color:#1087d6"
                           type="text"
                           icon="mdi mdi-comment-text-outline mdi-24px"></el-button>
              </el-tooltip>

              <el-tooltip content='Acil Beklemeye Al' :open-delay="500" placement="bottom">
                <el-button @click="changeGecikmeDurum(scope.row, '2')" class="buttonDel"
                           style="margin-left: 15px !important;"
                           type="text"
                           icon="mdi mdi-rotate-right mdi-24px"></el-button>
              </el-tooltip>
              <el-tooltip content='Beklemeye Al' :open-delay="500" placement="bottom">
                <el-button @click="changeGecikmeDurum(scope.row, '0')"
                           style="margin-left: 15px !important; color:#f7ba2a"
                           type="text"
                           icon="mdi mdi-clock-alert-outline mdi-24px"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-if="(yetkiTuru == '2') && selectIcon === '2'">
          <el-table-column
              :label='$t("src.views.apps.genel.islem")'
              align="right"
              width="150"
          >
            <template slot-scope="scope">

              <el-tooltip content='Açıklama' :open-delay="500" placement="bottom">
                <el-button @click="open(scope.row)"
                           style="margin-left: 15px !important; color:#1087d6"
                           type="text"
                           icon="mdi mdi-comment-text-outline mdi-24px"></el-button>
              </el-tooltip>

              <el-tooltip content='Beklemeye Al' :open-delay="500" placement="bottom">
                <el-button @click="changeGecikmeDurum(scope.row, '0')"
                           style="margin-left: 15px !important; color:#f7ba2a"
                           type="text"
                           icon="mdi mdi-clock-alert-outline mdi-24px"></el-button>
              </el-tooltip>

              <el-tooltip content='İncele' :open-delay="500" placement="bottom">
                <el-button @click="changeGecikmeDurum(scope.row, '1')"
                           style="margin-left: 15px !important; color:#13ce66"
                           type="text"
                           icon="mdi mdi-check mdi-24px"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-if="(yetkiTuru == '1') && selectIcon === '2'">
          <el-table-column
              :label='$t("src.views.apps.genel.islem")'
              align="right"
              width="150"
          >
            <template slot-scope="scope">

              <el-tooltip content='Açıklama' :open-delay="500" placement="bottom">
                <el-button @click="open(scope.row)"
                           style="margin-left: 15px !important; color:#1087d6"
                           type="text"
                           icon="mdi mdi-comment-text-outline mdi-24px"></el-button>
              </el-tooltip>

              <el-tooltip content='Beklemeye Al' :open-delay="500" placement="bottom">
                <el-button @click="changeGecikmeDurum(scope.row, '0')"
                           style="margin-left: 15px !important; color:#f7ba2a"
                           type="text"
                           icon="mdi mdi-clock-alert-outline mdi-24px"></el-button>
              </el-tooltip>

              <el-tooltip content='İncele' :open-delay="500" placement="bottom">
                <el-button @click="changeGecikmeDurum(scope.row, '1')"
                           style="margin-left: 15px !important; color:#13ce66"
                           type="text"
                           icon="mdi mdi-check mdi-24px"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>

      </el-table>
      <div v-if="gecikmeler.length == []"
           style="width: 100%; height: 50px !important; display: flex; justify-content: center; align-items: center">
        {{ $t("src.views.apps.messages.noData") }}
      </div>

      <el-pagination
          background
          layout="sizes,prev, pager, next, jumper, total"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="page"
          :next-click.sync="page"
          :prev-click.sync="page"
          @next-click="pageChange"
          @prev-click="pageChange">
      </el-pagination>

    </div>
    <aciklama-dialog v-if="dialogVisibleYonetici" :dialogvisible.sync="dialogVisibleYonetici"
                     :userdata="userDataBGSurec"></aciklama-dialog>
  </div>
</template>

<script>
import EventBus from '@/components/event-bus'
import notification from '../../../notification'
import functions from '../../../functions'
import personelService from '@/WSProvider/PersonelService'
import AciklamaDialog from '@/components/AciklamaDialog'

var moment = require('moment');

export default {
  name: "GecikmeListesi",
  components: {
    AciklamaDialog,
  },
  data() {
    return {
      isDesktop: false,
      loading: false,
      searchLoading: false,
      stateLoading: false,
      dialogVisibleYonetici: false,

      //editDialogLoading: false,
      editDialog: false,
      editDialogData: {},

      musteriList: [],
      yetkiListesi: [],
      gecikmeler: [],
      yetkiTuru: "",

      radio: "0",
      musteriForm: {
        musteriAdi: "",
        eposta: "",
        firmaAdi: "",
        unvan: "",
        telefon: ""
      },
      rulesMusteri: {},

      sizes: 10,
      selectIcon: "0",
      page: 0,
      postPage: 0,
      total: 4,
      sozlesmeKategoriAdiAra: "",
      personelAdiAra: "",
      musteriAdiAra: "",
      projeAdiAra: "",
      sozlesmeAdiAra: "",
      bitmesiGerekenTarihAra: "",
      bitisTarihiAra: "",
      gecikenGunAra: "",
      aciklamaAra: ""
    }
  },

  created() {
    this.checkDevice();
    if (localStorage.getItem("userDataBGSurec")) {
      this.yetkiTuru = this.$store.getters.getYetkiTuru;
      this.yetkiListesi = this.$store.getters.getYetkiListesi;
      //console.log(this.yetkiListesi)
    }
  },

  mounted() {
    this.radio = this.yetkiTuru == "2" ? "0" : "2"
    this.selectIcon = this.yetkiTuru == "2" ? "0" : "2"
    //this.getMusteriListesi();
    this.getGecikenSozlesmeler();
    window.addEventListener('resize', this.checkDevice);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice);
  },

  methods: {
    open(row) {
      //console.log("row")
      //console.log(row)


      this.userDataBGSurec = {
        row: row
      };

      this.dialogVisibleYonetici = true
    },

    checkDevice() {
      this.isDesktop = window.innerWidth >= 768;
    },

    updatePage(data) {
      // console.log(data)
      this.$store.commit('changeTatilUpdateData', data.resmiTatilID)
      functions.routeSayfa("Tatil Güncelle", this)
    },

    dateFarkHesapla(tamamlanmaTarihi, gecikenTamamlanmaTarihi) {
      if (gecikenTamamlanmaTarihi == "") gecikenTamamlanmaTarihi = new Date()
      // console.log("tamamlanan "+tamamlanmaTarihi)
      // console.log("beklenen "+gecikenTamamlanmaTarihi)
      const oneDay = 24 * 60 * 60 * 1000;
      const tamamlanmaDate = new Date(tamamlanmaTarihi);
      const gecikenTamamlanmaDate = new Date(gecikenTamamlanmaTarihi);
      const fark = Math.round(Math.abs((gecikenTamamlanmaDate - tamamlanmaDate) / oneDay));
      return fark;
    },

    gecikmeAra() {
      this.postPage = 0;
      this.page = 1
      this.dateVisible = false;
      //siparisNo, adSoyad, telefon, country, city, state, baslangicTarihi, bitisTarihi, durum, baslangic, limit,
      if (this.sozlesmeKategoriAdiAra.length > 0 || this.personelAdiAra.length > 0 || this.musteriAdiAra.length > 0 || this.projeAdiAra.length > 0 || this.sozlesmeAdiAra.length > 0 || this.gecikenGunAra.length > 0 || this.aciklamaAra.length > 0) {
        try {
          this.searchLoading = true;
          //kullaniciAdi, adSoyad, mail, telefon, durum, baslangic, limit)
          personelService.gecikenSozlesmelerFiltrele(this.sozlesmeKategoriAdiAra, this.personelAdiAra, this.musteriAdiAra, this.projeAdiAra, this.sozlesmeAdiAra, this.gecikenGunAra, this.aciklamaAra, this.postPage, this.sizes, this.selectIcon).then(response => {
            if (response.status == 200) {
              this.gecikmeler = response.data;
              this.total = response.count
            }
            localStorage.setItem("userDataBGSurec", response.token);
            this.searchLoading = false;
          }).catch(err => {
            if (err.responseJSON) {
              let error = err.responseJSON
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.gecikmeler = []
              }
            } else {
              //notification.Status(503, this)
            }
            this.searchLoading = false;
          })
        } catch (e) {
          // notification.Status("danger", this, "An error occurred during the customer search process.")
          this.searchLoading = false
        }
      } else {
        //this.getMusteriListesi();
        this.getGecikenSozlesmeler();
        // this.getMusteriSayisi();
      }
    },

    changeGecikmeDurum(row, durum) {
      //console.log(row)
      this.$confirm("İş durumu değiştirmek istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
        confirmButtonText: this.$t("src.views.apps.genel.yes"),
        cancelButtonText: this.$t("src.views.apps.genel.no"),
        type: 'warning'
      }).then(() => {
        try {
          this.stateLoading = true;
          personelService.gecikmeDurumDegis(row.gecikmeID, durum).then(response => {
            if (response.status == 200) {

              notification.Status("success", this, "İş durumu başarıyla güncellendi");
              // this.getMusteriListesi();
              this.getGecikenSozlesmeler();
            }
            this.stateLoading = false;
            localStorage.setItem("userDataBGSurec", response.token);
          }).catch(err => {
            if (err.responseJSON) {
              let error = err.responseJSON
              if (error.status == 401) {
                notification.Status(150, this);
              } else notification.Status("errorMsg", this, error.errMsg)
            } else {
              //notification.Status(503, this)
            }
            this.stateLoading = false;
          })
        } catch (e) {
          // notification.Status("success", this, "An error occurred while fetching the customer number.");
          this.stateLoading = false;
        }
      })
    },


    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.searchLoading = false;

      this.getGecikenSozlesmeler();
    },


    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.searchLoading = false;
    },

    getGecikenSozlesmeler() {
      try {
        this.loading = true;
        var currentYear = getCurrentYear();

        personelService.gecikenSozlesmeler(this.postPage, this.sizes, this.selectIcon).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.gecikmeler = response.data;
            this.total = response.count
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.gecikmeler = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        this.loading = false
      }
    },


    routeMusteri(name, scope) {
      if (scope) {
        this.$store.commit('changeTatilUpdateData', scope);
      }
      functions.routeSayfa(name, this);
    },

    indexMethod(index) {
      return ((this.page - 1) * 10) + index + 1;
    },

    handleSizeChange(val) {
      this.sizes = val;
      //this.getMusteriListesi();
      this.getGecikenSozlesmeler();
    },

    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      this.getGecikenSozlesmeler();
      document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
      this.ozelGunAra = ""
      this.turAra = ""
      this.baslangicAra = ""
      this.bitisAra = ""
      this.toplamGunAra = ""
    },

    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      //this.getMusteriListesi();
      this.getGecikenSozlesmeler();
    },
  }
}

function getCurrentYear() {
  var date = new Date();
  var year = date.getFullYear();
  return year;
}

</script>

<style lang="scss">
.el-table .info-row {
  background: rgba(125, 199, 225, 0.45);
}

.el-table .error-row {
  background: rgba(249, 0, 26, 0.08);
}

.el-table .success-row {
  background: rgba(3, 249, 0, 0.08);
}

.el-table .warning-row {
  background: rgba(245, 249, 0, 0.08);
}
</style>
<style lang="scss" scoped>
.refresh {
  color: #fff;
  float: right;
  padding: 0 !important;
  transition: all 0.5s;

}

.refresh:hover {
  transform: rotate(360deg);
}

.el-pagination {
  margin-top: 20px;
  float: right;
}

.el-radio-button--mini .el-radio-button__inner label {
  position: relative;
  bottom: 5px;
}

.kaldirilmis {
  background: #ec205f;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px
}

</style>
<style lang="scss">
.slide-fade-enter-active {
  transition: all .3s ease;
}


@media (min-width: 768px) {
  .el-radio-button--mini .el-radio-button__inner {
    padding: 0 10px !important;
    font-size: 11px;
    border-radius: 0;
  }
}


.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}
</style>